/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.filterSearchContainer {
    display: flex;
    width: 100%;
    margin-top: 5vh;
    justify-content: space-between;
    padding: 5%;
}

.filterCheckBoxes {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.btn {
    background-color: white;
    border: 1px solid #cccccc;
    color: #696969;
    padding: 0.5rem;
    text-transform: lowercase;
}

.delete__button {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 24px;
    height: 24px;
    text-align: center;
    color: white;
    border-radius: 50%;
    background: #da1a35;
    font-size: 0.9rem;
    font-weight: 100;
    line-height: 24px;
    cursor: pointer;
}

.btn--block {
    display: block;
    width: 30%;
    margin: 0 auto;
}

.cards {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.cards__item {
    /* display: -webkit-box; */
    /* display: flex; */
    padding: 1rem;
}

@media (min-width: 20rem) {
    .cards__item {
        width: 100%;
    }
}

@media (min-width: 21rem) {
    .cards__item {
        width: 50%;
    }
}

@media (min-width: 56rem) {
    .cards__item {
        width: 22%;
    }
}

.card {
    background-color: white;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.card:hover .card__image {
    -webkit-filter: contrast(100%);
    filter: contrast(100%);
}

.card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
}

.card__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    -webkit-filter: contrast(70%);
    filter: contrast(70%);
    overflow: hidden;
    position: relative;
    -webkit-transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    -o-transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91),
        -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
}

.card__image::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

@media (min-width: 40rem) {
    .card__image::before {
        padding-top: 66.6%;
    }
}

.card__image--room {
    background-image: url(https://www.escapegameover.com/img/event.jpg);
}

.card__title {
    color: #696969;
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.card__text {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 0;
    margin-top: 0.375rem;
}

textarea {
    resize: none;
}

@media (max-width: 500px) {
    .btn--block {
        width: 45%;
    }
}

.resultP {
    margin: 15vh auto;
    font-size: 22px;
}

.deletePopupContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    color: black;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(0, 0, 0, 0.7);
    background-color: white;
    z-index: 12;
    width: 150px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.deletePopupContainer div {
    cursor: pointer;
}

.deletePopupContainer div:hover {
    cursor: pointer;
    color: #da1a35;
}

.popupOverlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;
}
