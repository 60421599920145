.checkBoxes__item {
    display: flex;
    justify-content: space-between;
    width: 100px;
    height: 35px;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    display: inline-block;
    font-size: 0px;
    cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: -5px;
    width: 22px;
    height: 22px;
    border: 1px solid #cccccc;
    background: transparent;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    top: -5px;
    left: 15px;
    font-size: 24px;
    line-height: 0.8;
    color: #da1a35;
    transition: all 0.2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #999999;
    background-color: #dddddd;
}

[type="checkbox"]:disabled:checked + label:after {
    color: #999999;
}

[type="checkbox"]:disabled + label {
    color: #aaaaaa;
}

/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    border: 2px solid #999999;
}
