.navBar {
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 25px;
    margin-top: 50px;
}

nav li {
    position: relative;
}

.imgFluid {
    max-width: 100%;
    height: auto;
}

img {
    width: 100px;
    height: 100px;
}

.navBarIcons {
    width: 45px;
    height: 45px;
}

.menuLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style-type: none;
    width: 15vw;
    padding: 0;
}

.navBar:first-child,
.navBar:nth-child(2) {
    flex: 1 0 33%;
}

.navBar:last-child {
    flex-basis: 100%;
    display: flex;
}

.pseudoItem {
    width: 87px;
}

@keyframes example {
    from {
        color: #00ffda;
    }
    to {
        color: orange;
    }
}

.pendingsCounter {
    position: absolute;
    top: -10px;
    right: -5px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    background: #0e272c;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 24px;
    animation-name: example;
    animation-duration: 6000ms;
    animation-iteration-count: infinite;
}

@media (max-width: 600px) {
    .navBar {
        height: auto;
    }

    .menuLinks {
        width: 40vw;
    }

    .pseudoItem {
        display: none;
    }
}
