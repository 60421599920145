.searchBar___container {
    position: relative;
    height: 60px;
    margin: 2% auto;
    width: 20%;
}

#searchBar input[type="text"] {
    height: 60px;
    font-size: 25px;
    display: inline-block;
    font-weight: 100;
    border: none;
    outline: none;
    color: white;
    padding: 3px;
    padding-right: 60px;
    width: 0px;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    z-index: 3;
    -webkit-transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    -o-transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
}

#searchBar input[type="text"]:focus:hover {
    border-bottom: 1px solid white;
}

#searchBar input[type="text"]:focus {
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid white;
    cursor: text;
}

#searchBar input[type="submit"] {
    height: 40px;
    width: 40px;
    display: inline-block;
    color: red;
    background: url("../../../assets/search.svg") center center no-repeat;
    text-indent: -10000px;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0.4;
    cursor: pointer;
    -webkit-transition: opacity 2.5s ease;
    -o-transition: opacity 2.5s ease;
    transition: opacity 2.5s ease;
}

#searchBar input[type="submit"]:hover {
    opacity: 0.8;
}

@media (max-width: 600px) {
    .searchBar___container {
        width: 70%;
    }
}
