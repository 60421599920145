/* .filterSearchContainer {
    display: flex;
    width: 100%;
    margin-top: 5vh;
}

.filterCheckBoxes {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
} */

@media (max-width: 600px) {
    .filterSearchContainer {
        flex-direction: column;
    }

    .filterCheckBoxes {
        width: 100%;
        align-content: center;
        margin: 15px auto;
    }

    .searchButton {
        width: 100%;
        padding: 5%;
    }
}

/* TABLE STYLE */

table,
th,
td {
    /* border: solid 1px #000; */
    text-align: left;
    padding: 10px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 65%;
    /* border: 1px solid #ddd; */
    margin: 0 auto;
}

tbody {
    background-color: rgba(242, 242, 242, 0%);
    color: white;
}

caption {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}

tr:nth-child(even) {
    background-color: rgba(242, 242, 242, 3%);
}

td img {
    cursor: pointer;
    width: 30px;
}

@media (max-width: 500px) {
    table,
    th,
    td {
        padding: 4px;
        font-size: 13px;
    }

    table {
        width: 100%;
    }

    td img {
        width: 30%;
    }
}
