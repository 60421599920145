.errorMessage {
    color: red;
    text-align: center;
    margin-bottom: 5vh;
}

.backButton {
    background-color: #da1a35;
    border: none;
    text-align: center;
    float: left;
    width: 75px;
    height: 40px;
    position: relative;
    color: white;
    font-weight: 100;
    font-size: 18px;
    color: #ffffff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    margin: 15px;
}

.formContainer {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.formItem {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 50px;
}

.formItem-2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 50px;
}

.formItem p {
    color: white;
    margin-top: 5vh;
}

.formItem p span {
    float: right;
}

label {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 80px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.formItem-2 label {
    line-height: 45px;
}

.formItem-2 textarea {
    background-color: #3f3f3f;
    border: none;
    color: white;
    padding: 1.2rem;
}

.row {
    display: flex;
    justify-content: space-between;
    height: 150px;
}

.checkRow {
    display: flex;
    justify-content: space-between;
    height: 150px;
}

/* .dropDown {
    flex-grow: 3;
} */

.label {
    line-height: 50px;
    /* width: 80px; */
}

.textColumn {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.textColumn textarea {
    border-radius: 9px;
    outline: none;
}

.solutionInput {
    display: flex;
    flex-direction: column;
}

.solutionInput input[name="solution"] {
    background-color: #3f3f3f;
    color: white;
    border: none;
    border-radius: 9px;
    outline: none;
    padding: 5px;
    margin-bottom: 5px;
}

.checkBoxStyle {
    width: 50px;
    height: 50px;
}

.resolved {
    position: relative;
    left: 3em;
    bottom: 3em;
}

.submitButton {
    border-radius: 30px;
    background-color: #da1a35;
    border: none;
    text-align: center;
    float: left;
    width: 150px;
    height: 40px;
    position: relative;
    color: white;
    font-weight: 100;
    font-size: 18px;
    color: #ffffff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
}

.formItem .optionDropDown {
    margin-bottom: 2vh;
}

.optionDropDown select {
    position: relative;
    background-color: #3f3f3f;
    color: white;
    padding: 12px;
    width: 100%;
    border: none;
    font-size: 20px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
    border-radius: 9px;
}

/*
.optionDropDown::after {
    content: "\f13a";
    font-family: FontAwesome;
    position: relative;
    top: 0;
    right: 0;
    width: 20px;
    height: 50px;
    text-align: center;
    font-size: 28px;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
} */

.optionDropDown:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.optionDropDown select option {
    padding: 30px;
    outline: none;
}

/* hover style just for information */
/* label:hover:before {
    border: 2px solid #4778d9 !important;
    background: #ffffff;
} */

@media (max-width: 600px) {
    .formContainer {
        width: 100%;
        flex-direction: column;
        padding: 15px;
    }

    .formContainer label {
        font-size: 15px;
    }

    .formItem,
    .formItem-2 {
        width: auto;
        margin: 0;
    }
}

.optionDropDown select {
    /* inline SVG */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 2px;
    border: none;
    color: #ffffff;
    padding: 10px 30px 10px 10px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}
