/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: Bankgothic Regular;
    src: url(./assets/font/BANKGOTHIC-REGULAR.TTF);
}

html {
    background-image: radial-gradient(#0e272c 5%, #000f1e);
    height: 100%;
}

body {
    color: #999999;
    font-family: Bankgothic Regular;
    font-style: regular;
    font-weight: 400;
    letter-spacing: 0;
    /* padding: 1rem; */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
}

.App {
    width: 100%;
}

.buttonAdd {
    width: 55px;
    height: 55px;
    background-image: url("./assets/addbutton.png");
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 15px auto;
}

.ticket {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
}

.loginPopupContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    color: white;
    transform: translate(-50%, -50%);
    /* background-color: #da1a35; */
    z-index: 12;
    max-width: 300px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
