form {
    display: flex;
    flex-direction: column;
}

form input {
    background: #696a6c;
    border-color: transparent;
    width: 250px;
    height: 30px;
    outline-color: transparent;
    /* border-radius: 5px; */
    color: white;
    background-color: rgba(255, 255, 255, 7%);
    font-size: 18px;
    margin: 5px;
}

form button {
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: white;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}

.logOutButton {
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: white;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}
